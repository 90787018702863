* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background: #e6e8ed;
  --text-color: #3d5a80;
  --accent: #81a3d1;
}

body {
  background-color: var(--background);
  color: var(--text-color);
  font-family: Inter;
  overflow: hidden;
  height: 100dvh;
}

#root {
  overflow-y: scroll;
  height: 100%;
  background-color: rgba(229, 232, 237, 0.5);
  transition: all 500ms ease-in;
  backdrop-filter: blur(5px);
}

.section {
  height: 100dvh;
}

.section .section-header {
  color: var(--accent);
  text-orientation: mixed;
  writing-mode: vertical-rl;
  font-size: 50px;
  opacity: 0.5;
}

@media (max-width: 800px) {
  .section {
    height: unset;
    margin-bottom: 100px;
  }

  .section .section-header {
    text-orientation: upright;
    writing-mode: unset;
    font-size: 40px;
    margin-left: 10px;
  }
}
