.contact {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact .container {
  width: 100%;
  max-width: 1150px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.contact .container .form-container {
  display: grid;
  padding: 40px;
  background-color: var(--accent);
  border-radius: 10px;
  width: 100%;
}

.contact .container .form-container .form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.contact .container .form-container .form-grid input {
  border: none;
  padding: 10px;
  font-size: 24px;
  border-radius: 5px;
  color: var(--text-color);
  width: 100%;
}

.contact .container .form-container .form-grid textarea {
  grid-column: 1/3;
  border-radius: 5px;
  resize: 0;
  font-size: 24px;
  padding: 10px;
  resize: none;
}

.contact .container .form-container button {
  border: none;
  font-size: 25px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 20px auto 0 auto;
  background-color: white;
  color: var(--text-color);
  transition: all 100ms ease-in;
}

.contact .container .form-container button:hover {
  background-color: var(--text-color);
  color: white;
  scale: 1.05;
}

@media (max-width: 600px) {
  .contact .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    gap: 20px;
  }

  .contact .container .section-header {
    margin-left: 0;
  }

  .contact .container .form-container {
    padding: 30px 10px;
  }

  .contact .container .form-container .form-grid {
    display: flex;
    flex-direction: column;
  }
}
