.navbar {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in;
  z-index: 10;
  margin-bottom: -100px;
}

.navbar .container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
}

.navbar .container .logo {
  position: absolute;
  left: 20px;
  height: 100%;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
}

.navbar .container .links {
  display: flex;
  gap: 10px;
}

.navbar .container .links a {
  color: var(--text-color);
  font-size: 25px;
  padding: 0px 10px;
  transition: all 100ms ease-in;
  cursor: pointer;
}

.navbar .container .links a:hover {
  /* border-bottom: 4px solid var(--accent); */
  color: var(--accent);
}

.navbar .container .links .active {
  /* border-bottom: 4px solid var(--accent); */
  font-size: 25px;
  font-weight: 600;
  color: var(--accent);
}

.navbar .container .socials {
  position: absolute;
  right: 20px;
  display: flex;
  gap: 10px;
}

.navbar .container .socials .link {
  background-color: #f5f5f5;
  color: var(--accent);
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  transition: all 100ms ease-in;
}

.navbar .container .socials .link:hover {
  color: #f5f5f5;
  background-color: var(--accent);
}

.navbar .container .socials .link svg {
  width: 50px;
  height: 50px;
  transition: all 50ms ease-in;
}

.link-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vertical-line {
  height: 30px;
  width: 3px;
  background-color: var(--accent);
  border-radius: 5px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  /* color: var(--accent); */
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.arrow.open {
  transform: rotate(180deg); /* Rotates the arrow when the dropdown is open */
}

.dropdown button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  font-size: 40px; /* Set font size */
  color: inherit; /* Inherit the text color from its parent */
  text-align: left; /* Align text to the left */
  cursor: pointer; /* Show pointer on hover */
  display: inline; /* Make it inline with other elements */
  outline: none;
  color: white;
  font-weight: 500;
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown-menu a {
  padding: 10px 20px;
  text-decoration: none;
  color: var(--text-color);
  font-size: 25px;
}

@media (max-width: 800px) {
  .navbar {
    position: sticky;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 80px;
    margin-bottom: 0;
  }

  .navbar .logo {
    height: 100%;
  }

  .navbar .menu-icon {
    height: 50px;
    width: 50px;
    fill: var(--text-color);
  }

  .navbar__menu {
    position: sticky;
    top: 0;
    margin-bottom: -100dvh;
    width: 100%;
    height: 100dvh;
    background-color: rgba(129, 163, 209, 0.9);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    align-items: center;
    /* justify-content: center; */
    gap: 30px;
    z-index: 20;
  }

  .navbar__menu > a {
    font-size: 40px;
    font-weight: 500;
    color: white;
  }

  .navbar__menu > .active {
    font-size: 45px;
    font-weight: 600;
    text-decoration: underline;
  }

  .navbar__menu .socials {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 20px;
    padding-bottom: 40px;
  }

  .navbar__menu .socials .link {
    background-color: #f5f5f5;
    color: var(--accent);
    height: 65px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    transition: all 100ms ease-in;
  }

  .navbar__menu .socials .link:hover {
    color: #f5f5f5;
    background-color: var(--accent);
  }

  .navbar__menu .socials .link svg {
    width: 50px;
    height: 50px;
    transition: all 50ms ease-in;
  }

  /* .arrow {
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(-180deg);
  }

  .arrow.open {
    transform: rotate(180deg);
    color: white;
  } */
}
