.FitCheck-container {
  margin: 0 auto;
  text-indent: center;
  max-width: 1200px;
  align-content: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 125px;
}

.FitCheck-header {
  font-style: italic;
  font-size: 40px;
  color: var(--accent);
  font-weight: 700;
  padding-bottom: 10px;
}

.FitCheck-analysis .analysis-header {
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 10px;
  color: var(--accent);
  font-style: italic;
}

.FitCheck-analysis .analysis-text {
  font-size: 18px;
  padding-bottom: 20px;
}

.FitCheck-analysis {
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  margin-top: 50px;
}

.FitCheck-analysis-middle .analysis-header {
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 10px;
  color: var(--accent);
  font-style: italic;
}

.FitCheck-analysis-middle .analysis-text {
  font-size: 18px;
  padding-bottom: 20px;
}

.FitCheck-analysis-middle {
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  margin-top: 50px;
}
.analysis-div {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  box-sizing: content-box;
  /* margin-right: 5px; */
  /* justify-content: space-between; */
}

.analysis-div::-webkit-scrollbar {
  width: 8px;
}

.analysis-div::-webkit-scrollbar-track {
  border-radius: 4px;
}

.analysis-div::-webkit-scrollbar-thumb {
  background-color: #3d5a80;
  border-radius: 4px;
}

.FitCheck-page-titles {
  font-size: 30px;
  padding-bottom: 20px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.FitCheck-swiper {
  margin-top: 50px;
  width: 100%;
  height: 400px;
}

.FitCheck-swiper .slide {
  display: grid;
  grid-template-columns: 5fr 4fr;
  align-items: top;
  justify-content: left;
  /* background-color: var(--background); */
  gap: 10px;
}

.FitCheck-swiper .slide .text {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.FitCheck-swiper .slide .text-scroll {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 200px;
  padding-right: 10px;
  font-size: 18px;
}

.FitCheck-swiper .slide .text-scroll::-webkit-scrollbar {
  width: 8px;
}

.FitCheck-swiper .slide .text-scroll::-webkit-scrollbar-thumb {
  background-color: #3d5a80;
  border-radius: 4px;
}

.FitCheck-swiper .slide .text-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #2c4a6d;
}

.FitCheck-swiper .slide .text .name {
  font-size: 24px;
  font-weight: bold;
  color: #3d5a80;
}

/* .FitCheck-swiper .slide .text .position {
        font-size: 16px;
        font-weight: normal;
        color: #777;
        margin-bottom: 15px;
      } */

.FitCheck-swiper .slide .text p {
  font-size: 18px;
  /* line-height: 1.6; */
  color: var(--text-color);
}

.FitCheck-swiper .slide .text .section-title {
  font-weight: bold;
  color: #3d5a80;
}

.FitCheck-page-titles {
  color: var(--accent);
}

.FitCheck-swiper .slide .text button {
  background-color: transparent;
  border: none;
  font-size: 22px;
  font-weight: bold;
  color: #3d5a80;
  cursor: pointer;
  text-align: left;
  margin-top: 10px;
}

.FitCheck-swiper .slide .text button:hover {
  color: var(--accent);
}

.FitCheck-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.FitCheck-swiper .slide img {
  /* width: 100%; */
  max-height: 400px;
  /* object-fit: cover; */
  border-radius: 10px;
}

.FitCheck-swiper-pagination-bullet {
  background-color: #3d5a80;
  opacity: 0.7;
}

.FitCheck-swiper-pagination-bullet-active {
  background-color: #2c4a6d;
  opacity: 1;
}

/* @media (max-width: 800px) {
  .FitCheck-swiper .slide {
    grid-template-columns: 1fr;
    gap: 20px;
    flex-direction: column-reverse;
  }

  .FitCheck-swiper .slide .text {
    padding: 15px;
  }

  .FitCheck-swiper .slide .text-scroll {
    max-height: 150px;
  }

  .FitCheck-swiper {
    height: 100vh;
  }
} */

@media (max-width: 800px) {
  .FitCheck-container {
    padding: 0 20px;
    padding-bottom: 100px;
  }

  .title {
    margin-top: 100px;
  }
  .FitCheck-image {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .FitCheck-header {
    font-size: 32px;
  }
  .FitCheck-analysis .analysis-text {
    font-size: 18px;
    padding-bottom: 20px;
  }

  .FitCheck-analysis {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .FitCheck-analysis-middle {
    /* padding-bottom: 30px; */
    display: flex;
    flex-direction: column-reverse;
  }
  .FitCheck-analysis-middle .analysis-text {
    font-size: 18px;
    padding-bottom: 20px;
  }

  .FitCheck-analysis-middle .analysis-header {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 10px;
    color: var(--accent);
    font-style: italic;
  }
  .analysis-div {
    padding-top: 20px;
    height: 300px;
  }

  .FitCheck-swiper .slide {
    grid-template-columns: 1fr;
    gap: 20px;
    flex-direction: column-reverse;
  }

  .FitCheck-swiper .slide .text {
    padding: 15px;
  }

  .FitCheck-swiper .slide .text-scroll {
    max-height: 150px;
  }

  .FitCheck-swiper .slide img {
    /* width: 100%; */
    max-height: 300px;
    /* object-fit: cover; */
  }
  .FitCheck-swiper {
    height: auto;
    padding-bottom: 20px;
  }
}
