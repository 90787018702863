.projects {
  display: flex;
  justify-content: center;
}

.projects .container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.projects .container .swiper {
  width: 100%;
  height: 400px;
}

.projects .container .swiper .slide {
  display: grid;
  grid-template-columns: 5fr 4fr;
}

.projects .container .swiper .slide .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projects .container .swiper .slide .text-scroll {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.hidden {
  display: none;
}

.projects .container .swiper .slide .text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projects .container .swiper .slide .header {
  flex-shrink: 0;
  margin-bottom: 10px;
}

.projects .container .swiper .slide .text-scroll {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  max-height: 200px;
}

.projects .container .swiper .slide .footer {
  flex-shrink: 0;
  margin-top: 10px;
  text-align: left;
}

.projects .container .swiper .slide .text-scroll::-webkit-scrollbar {
  width: 8px;
}

.projects .container .swiper .slide .text-scroll::-webkit-scrollbar-thumb {
  background-color: #3d5a80;
  border-radius: 4px;
}

.projects
  .container
  .swiper
  .slide
  .text-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #3d5a80;
}

.projects .container .swiper .slide .text .name {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
}

.projects .container .swiper .slide .text .position {
  font-size: 16px;
  font-weight: 400;
}

.projects .container .swiper .slide .text p {
  font-size: 20px;
  font-weight: 400;
}

.projects .container .swiper .slide .text button {
  background-color: transparent;
  border: none;
  width: fit-content;
  font-size: 22px;
  color: var(--text-color);
  font-weight: 600;
}

.projects .container .swiper .slide .text button:hover {
  color: var(--accent);
}

.projects .container .swiper .slide img {
  width: 100%;
}

.section-title {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.view-website-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.view-website-link p {
  margin-top: 10px;
  font-weight: bold;
  font-style: italic;
  color: var(--text-color);
  cursor: pointer;
}

.view-website-link p:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  .projects .container {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .projects .container .swiper {
    height: 100dvh;
  }

  .projects .container .swiper .slide {
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
    gap: 30px;
    max-height: 90dvh;
    overflow-y: auto;
    margin: auto 0;
  }

  .projects .container .swiper .slide .text {
    padding: 0px 15px;
    gap: 30px;
  }
}
