.about {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about .container {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 5fr;
  max-width: 1350px;
  margin-top: 50px;
}

.about .container img {
  width: 85%;
  /* border-radius: 100%; */
}

.about .container .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about .container .text h1 {
  font-size: 45px;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
}

.about .container .text p {
  font-size: 30px;
  font-weight: 200;
  font-style: italic;
}

.about .container .text h1 span {
  font-weight: 700;
  color: var(--accent);
}

.about .container .text .buttons {
  display: flex;
  gap: 30px;
}

.about .container .text .buttons .button {
  background-color: var(--accent);
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  padding: 8px 20px;
  border-radius: 5px;
  margin-top: 40px;
  text-decoration: none;
}

.about .container .text .buttons .button:hover {
  background-color: var(--text-color);
}

@media (max-width: 600px) {
  .about {
    height: fit-content;
    align-items: center;
  }
  .about .container {
    display: flex;
    flex-direction: column;
  }

  .about .container .text {
    padding: 0px 15px;
    display: flex;
    align-content: center;
    align-items: center;
  }

  .about .container .text h1 {
    font-size: 35px;
  }

  .about .container .text p {
    font-size: 25px;
  }

  .about .container .text .buttons {
    display: flex;
    justify-content: center;
  }
  .about .container .text .buttons .button {
    font-size: 25px;
    display: flex;
    align-items: center;
  }
  .about .container .image img {
    width: 85%;
    display: block;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}
