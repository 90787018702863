.experience {
  display: flex;
  justify-content: center;
  height: fit-content;
}

.experience .container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 40px;
}

.experience .container .section-header {
  position: sticky;
  top: 140px;
  height: fit-content;
  margin-top: 40px;
}

.experience .container .timeline {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: fit-content;
  padding: 50px 0px;
}

.experience .container .more-info {
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px); /* Ensures it fits the viewport */
  background-color: var(--accent);
  width: 450px;
  flex-shrink: 0;
  padding: 40px 35px;
  color: white;
  overflow-y: auto; /* Enables scrolling when content exceeds the container height */
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: clamp(10px, 2vw, 13px);
  max-height: 100vh; /* Limits the height to the viewport size */
}

.experience .container .more-info .grid {
  display: flex;
  align-items: center;
  gap: 10px;
}

.experience .container .more-info .grid img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.experience .container .more-info ul {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
  font-size: 16px;
  padding-left: 10px;
}

.description-font {
  font-size: 16px;
  font-style: italic;
}

.description-word {
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
}

.content-container {
  margin-left: 20px;
  margin-top: -20px;
  font-size: 15px;
}

.job-container {
  padding-top: 50px;
  padding-left: 10px;
}

.experience .container .timeline::before {
  content: "";
  height: calc(100% - 150px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  background-color: var(--text-color);
}

.experience .container .timeline .experience-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: calc(50% - 40px);
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  transition: all 100ms ease-in;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: clamp(12px, 2vw, 15px);
}

.experience .container .timeline .experience-card:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.experience .container .timeline .experience-card:hover h1 {
  text-decoration: underline;
  font-size: 34px;
  transition: all 100ms ease-in;
}

.experience .container .timeline .experience-card:nth-child(2n) {
  margin-left: auto;
}

.experience .container .timeline .experience-card .icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20px);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.experience .container .timeline .experience-card .icon img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.experience .container .more-info::-webkit-scrollbar {
  width: 8px;
}

.experience .container .more-info::-webkit-scrollbar-thumb {
  background-color: #3d5a80;
  border-radius: 4px;
}

.experience .container .more-info::-webkit-scrollbar-thumb:hover {
  background-color: #3d5a80;
}

@media (max-width: 800px) {
  .experience .container {
    flex-direction: column;
    gap: 20px;
  }

  .experience .container .section-header {
    position: relative;
    top: 0;
    margin-top: 0;
  }

  .experience .container .more-info {
    display: none;
  }

  .experience .container .timeline {
    padding: 0px 15px;
  }

  .experience .container .timeline::before {
    height: calc(100% - 50px);
    left: 40px;
    transition: unset;
  }

  .experience .container .timeline .experience-card {
    margin-left: 70px !important;
    width: calc(100% - 80px);
  }

  .experience .container .timeline .experience-card .icon {
    left: 40px;
  }

  .experience .container .timeline .experience-card h1 {
    font-size: 22px;
  }

  .experience .container .timeline .experience-card .time {
    font-size: 14px;
  }
}
